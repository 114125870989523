<!-- =========================================================================================
    File Name: ChatLog.vue
    Description: Chat Application - Log of chat
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<!-- hasSentPreviousMsg -->
<template>
  <div
    id="component-chat-log"
    class="m-8 pb-6 vs-con-loading__container"
    v-if="activeChatUser"
  >
    <vs-popup
      classContent="popup-example"
      :title="lang.attentionRequests.hsmMessage[languageSelected]"
      :active.sync="hsmPopup"
    >
      <div>
        <pre>{{ `${JSON.stringify(hsm, undefined, 2)}` }}</pre>
      </div>
    </vs-popup>
    <vs-divider color="#2786e5">
      <span
        @click="getMessages(true, false)"
        class="cursor-pointer"
        v-show="moreMessage"
      >
        {{ lang.attentionRequests.showMoreMessage.yes[languageSelected] }}
      </span>
      <span v-show="!moreMessage">
        {{ lang.attentionRequests.showMoreMessage.not[languageSelected] }}
      </span>
    </vs-divider>
    <div v-for="(msg, index) in activeChatMessages" :key="index">
      <div
        v-if="!msg.specialEvent"
        class="flex items-start"
        :class="{
          'flex-row-reverse ':
            msg.isUser == 0 || msg.isUser == 2 || msg.isUser == 6
        }"
      >
        <vs-avatar
          v-if="
            index == 0 || activeChatMessages[index - 1].isUser != msg.isUser
          "
          v-tooltip="
            msg.user && msg.user.name
              ? msg.user.name
              : msg.isUser === 2 || msg.isUser == 6
              ? activeChatUser.botName
                ? activeChatUser.botName
                : null
              : activeChatUser
              ? activeChatUser.fullname
              : null
          "
          size="40px"
          class="m-0 flex-no-shrink p-0 text-dark"
          :class="avatarClass(msg)"
          :src="getPicture(msg.isUser)"
        ></vs-avatar>
        <div v-else class="mr-16"></div>
        <div
          v-if="
            !msg.text ||
            (msg.text.type !== 'image' && msg.text.type !== 'video')
          "
          class="
            msg
            break-words
            relative
            shadow-md
            rounded
            py-3
            px-4
            mb-2
            rounded-lg
            max-w-sm
          "
          :class="messageClass(msg)"
        >
          <!-- tipos de mensajes -->
          <!-- LOCATION -->
          <a
            v-if="msg.text.type == 'location'"
            :href="msg.text.url"
            target="_blank"
          >
            <feather-icon
              icon="MapPinIcon"
              svgClasses="h-6 w-6"
              class="mb-4"
              style="cursor: pointer"
            ></feather-icon>
            <br />
            {{ lang.attentionRequests.location[languageSelected] }}
          </a>
          <!-- TEXT -->
          <span v-if="msg.text.type == 'text'">
            <PreviewButtons
              v-if="
                msg.isUser == 2 &&
                msg.output &&
                msg.output.length &&
                msg.output[0].responseOptions &&
                msg.output[0].responseOptions.length
              "
              :msg="msg.output[0]"
            />
            <PreviewCard
              v-else-if="
                msg.isUser == 2 &&
                msg.output &&
                msg.output.length &&
                msg.output[0].responseCard &&
                msg.output[0].responseCard.length
              "
              :msg="msg.output[0]"
            />
            <span
              v-else-if="
                msg.input_type === 'VIDEO_CALL' &&
                msg.videoCall &&
                config.videocall &&
                videoCall &&
                videoCall.active &&
                msg.user && 
                msg.user._id === session.user.id
              "
            >
              <feather-icon
                icon="VideoIcon"
                svgClasses="h-6 w-6"
                class="mb-2"
                v-tooltip="lang.videoCall.title[languageSelected]"
              ></feather-icon>
              <div
                class="mb-2"
                v-if="new Date(msg._videoCallExpiresAt) > new Date()"
              >
                <vs-button color="success" @click="changeView">
                  {{ lang.videoCall.gotToActivePanelToJoin[languageSelected] }}
                </vs-button>
              </div>
              <div class="mb-2">
                <vs-alert icon="schedule" active="true" class="text-white" color="rgba(var(--vs-warning), 1)">
                  <div v-if="new Date(msg._videoCallExpiresAt) <= new Date()">
                    {{ lang.videoCall.expired[languageSelected] }} {{ new Date(msg._videoCallExpiresAt) | moment('from', 'now') }}
                  </div>
                  <div v-else>
                    {{ lang.videoCall.expires[languageSelected] }} {{ new Date(msg._videoCallExpiresAt) | moment('from', 'now') }}
                  </div>
                </vs-alert>
              </div>
            </span>
            <span
              v-else-if="
                msg.input_type === 'VIDEO_CALL' &&
                msg.videoCall &&
                config.videocall &&
                videoCall &&
                videoCall.active &&
                msg.user && 
                msg.user._id !== session.user.id
              "
            >
              <feather-icon
                icon="VideoIcon"
                svgClasses="h-6 w-6"
                class="mb-2"
                v-tooltip="lang.videoCall.title[languageSelected]"
              ></feather-icon>
              <div class="mb-2">
                <vs-alert active="true" class="text-white" color="rgba(var(--vs-warning), 1)">
                  {{ lang.videoCall.notAbleToJoin[languageSelected] }}
                </vs-alert>
              </div>
            </span>
            <div v-else-if="isDocument(msg.text.text)">
              <a :href="msg.text.text" target="_blank">
                <div class="vx-row mb-2">
                  <vs-col>
                    <div
                      class="icon-file-white"
                      :class="{ 'float-right': msg.isUser % 2 === 0 }"
                    >
                      <feather-icon
                        icon="FileIcon"
                        class="icon-large cursor-pointer"
                        :class="{
                          'text-white': msg.isUser % 2 === 0,
                          'text-primary': msg.isUser % 2 === 1
                        }"
                      ></feather-icon>
                    </div>
                  </vs-col>
                </div>
              </a>
            </div>
            <div v-else-if="isImageLink(msg.text.text)">
              <a :href="msg.text.text" target="_blank">
                <img
                  @load="autoScrollBottomFake"
                  :src="msg.text.text"
                  class="img-message"
                />
              </a>
            </div>
            <div v-else>
              <feather-icon
                v-if="msg.meta && msg.meta.hsm"
                icon="ExternalLinkIcon"
                svgClasses="h-6 w-6"
                class="mb-4"
                style="cursor: pointer"
                v-tooltip="lang.attentionRequests.hsmMessage[languageSelected]"
                @click="showHSM(msg.meta.hsm)"
              ></feather-icon>
              <feather-icon
                v-if="msg.meta && msg.meta.template"
                icon="ExternalLinkIcon"
                svgClasses="h-6 w-6"
                class="mb-4"
                style="cursor: pointer"
                v-tooltip="lang.attentionRequests.hsmMessage[languageSelected]"
                @click="showHSM(msg.meta.template)"
              ></feather-icon>
              <br v-if="msg.meta && msg.meta.hsm" />
              <br v-if="msg.meta && msg.meta.template" />
              <div
                v-if="hasHtmlTags(addLinksText(msg.text.text))"
                v-dompurify-html="processText(msg.text.text)"
              ></div>
              <div v-else-if="msg.text.text">
                <a :href="msg.text.text" v-if="validURL(msg.text.text)">
                  <text-highlight :queries="[contains]">
                    {{ msg.text.text }}
                  </text-highlight>
                </a>
                <span v-else>
                  <text-highlight :queries="[contains]">
                    {{ addLinksText(msg.text.text) }}
                  </text-highlight>
                </span>
              </div>
            </div>
          </span>
          <!-- AUDIO -->
          <audio
            @load="autoScrollBottomFake"
            v-if="msg.text.type == 'audio'"
            :src="parseUrl(msg.text.url)"
            class="audio-message"
            controls
          />
          <!-- KONECTA AUDIO -->
          <audio
            @load="autoScrollBottomFake"
            v-if="
              msg.input_type == 'AUDIO' &&
              !(msg.isUser == 0 || msg.isUser == 2 || msg.isUser == 6)
            "
            :src="parseUrl(msg.media_url)"
            class="audio-message mt-1"
            controls
          />
          <!-- FILE -->
          <a
            v-if="msg.text.type == 'file' && msg.text.name"
            :href="msg.text.url"
            target="_blank"
          >
            <feather-icon
              icon="FileIcon"
              svgClasses="h-6 w-6"
              class="mb-4"
              style="cursor: pointer"
            ></feather-icon>
            <br />
            <text-highlight :queries="[contains]">{{
              msg.text.name
            }}</text-highlight>
          </a>
          <a
            v-else-if="msg.text.type == 'file'"
            :href="msg.text.url"
            target="_blank"
          >
            <div class="vx-row mb-2">
              <vs-col>
                <div
                  class="icon-file-white"
                  :class="{ 'float-right': msg.isUser % 2 === 0 }"
                >
                  <feather-icon
                    icon="FileIcon"
                    class="icon-large cursor-pointer"
                    :class="{
                      'text-white': msg.isUser % 2 === 0,
                      'text-primary': msg.isUser % 2 === 1
                    }"
                  ></feather-icon>
                </div>
              </vs-col>
            </div>
          </a>
          <p v-if="msg.status && msg.status === 1" class="chat-time mt-1">
            {{ lang.attentionRequests.sending[languageSelected] }}
          </p>
          <p
            v-if="msg.status && msg.status === -1"
            class="chat-time mt-1 text-danger"
          >
            ⚠️
          </p>
          <p v-else class="chat-time mt-1">{{ msg.ts | moment(dateFormat) }}</p>
          <MessageSentiments
            v-if="bot.showEmotions && msg.isUser === 1"
            :key="msg._id"
            :message="msg"
          />
        </div>
        <div
          v-else
          class="
            msg
            break-words
            relative
            shadow-md
            rounded
            mb-2
            rounded-lg
            max-w-sm
          "
          :class="messageClass(msg)"
        >
          <!-- IMAGE -->
          <div v-if="msg.text.type == 'image' && msg.text.name">
            <text-highlight :queries="[contains]">{{
              msg.text.name
            }}</text-highlight>
          </div>
          <a
            v-if="msg.text.type == 'image'"
            :href="msg.text.url"
            target="_blank"
          >
            <img
              @load="autoScrollBottomFake"
              :src="parseUrl(msg.text.url)"
              class="img-message"
            />
          </a>
          <!-- VIDEO -->
          <div v-if="msg.text.type == 'video' && msg.text.name">
            <text-highlight :queries="[contains]">{{
              msg.text.name
            }}</text-highlight>
          </div>
          <video
            @load="autoScrollBottomFake"
            v-if="msg.text.type == 'video'"
            :src="parseUrl(msg.text.url)"
            class="img-message"
            controls
          />
          <div class="px-4 py-3">
            <p v-if="msg.status && msg.status === 1" class="chat-time mt-1">
              {{ lang.attentionRequests.sending[languageSelected] }}
            </p>
            <p
              v-if="msg.status && msg.status === -1"
              class="chat-time mt-1 text-danger"
            >
              ⚠️
            </p>
            <p v-else class="chat-time mt-1">
              {{ msg.ts | moment(dateFormat) }}
            </p>
          </div>
        </div>
      </div>

      <div v-else-if="msg.specialEvent == 1">
        <vs-divider>
          <span>
            {{
              lang.attentionRequests.specialEvents[msg.specialEvent - 1][
                languageSelected
              ]
            }}
          </span>
        </vs-divider>
      </div>
      <div v-else-if="msg.specialEvent == 2">
        <vs-divider>
          <span>
            {{
              lang.attentionRequests.specialEvents[msg.specialEvent - 1][
                languageSelected
              ]
            }}
          </span>
        </vs-divider>
      </div>
      <div v-else-if="msg.specialEvent == 100">
        <vs-divider />
        <div
          class="
            msg
            break-words
            relative
            shadow-md
            rounded
            py-3
            px-4
            mb-2
            rounded-lg
            max-w-sm
            border border-solid border-grey-light
            bg-white
          "
        >
          <div v-dompurify-html="msg.event.html"></div>
          <p class="chat-time">
            <small>
              <i>{{ msg.ts | moment(dateFormat) }}</i>
            </small>
          </p>
        </div>
        <vs-divider />
      </div>
    </div>
  </div>
</template>

<script>
import konecta from '@/services/konecta'
import { mapState, mapMutations, mapActions } from 'vuex'
import { FILE_FORMATS_MAP, CHAT_USER_STATUS } from '@/constants/constants'

export default {
  name: 'chat-log',
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  components: {
    PreviewCard: () => import('./previews/PreviewCard.vue'),
    PreviewButtons: () => import('./previews/PreviewButtons.vue'),
    MessageSentiments: () => import('@/components/MessageSentiments.vue')
  },
  data() {
    return {
      messages: [{}],

      length: 0,
      messagesLength: null,

      //datos del scroll update

      defaultNumMessages: 20,
      limit: 20,
      shown: 20,
      skip: 0,

      hsmPopup: false,
      hsm: null,

      chatUserStatusRequests: 0,
      getChatUserStatusInterval: null,
      timeMsToCheckChatUserStatus: 60000,
    }
  },
  computed: {
    dateFormat() {
      if (this.languageSelected === 'es') {
        return 'DD MM YYYY, h:mm:ss a'
      }

      return 'YYYY MM DD, h:mm:ss a'
    },
    statusChat() {
      return this.activeChatUser.status
    },

    // contactIndex() {
    //   return contacts.findIndex(contact => contact.id == this.userId);
    // },
    // userImg() {
    //   if (this.contactIndex !== -1) return this.contacts[this.contactIndex].img;
    // },
    // activeUserImg() {
    //   return this.$store.state.AppActiveUser.img;
    // },
    // senderImg() {
    //   return isSentByActiveUser => {
    //     if (isSentByActiveUser)
    //       return require(`@/assets/images/portrait/small/${
    //         this.$store.state.AppActiveUser.img
    //       }`);
    //     else
    //       return require(`@/assets/images/portrait/small/${
    //         this.contacts[this.contactIndex].img
    //       }`);
    //   };
    // },
    // hasSentPreviousMsg() {
    //   return (last_sender, current_sender) => last_sender == current_sender;
    // },

    moreMessage() {
      if (this.length <= this.messagesLength) {
        return false
      } else {
        return true
      }
    },

    ...mapState([
      'activeChatUser',
      'activeChatMessages',
      'lang',
      'languageSelected',
      'session',
      'bot',
      'contains',
      'config',
      'videoCall'
    ])
  },
  methods: {
    changeView() {
      let routeData = this.$router.resolve({
        path: '/attention-requests',
        query: {
          bot: this.activeChatUser.bot,
          chat: this.activeChatUser._id
        }
      })
      window.open(routeData.href, '_blank')
    },
    parseUrl(url) {
      const backendBaseUrl = this.config.konecta
      return /^\/bucket/.test(url) ? `${backendBaseUrl}${url}` : url
    },
    validURL(str) {
      var pattern = new RegExp(
        '^(https?:\\/\\/)' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i'
      ) // fragment locator
      return !!pattern.test(str)
    },
    checkImage(url) {
      return url.match(/\.(jpeg|jpg|gif|png)$/) != null
    },
    addLinksText(inputText) {
      if (!inputText) {
        return inputText
      }
      var replacedText, replacePattern1, replacePattern2, replacePattern3
      if (inputText) {
        //URLs starting with http://, https://, or ftp://
        replacePattern1 =
          /(\b(https?|ftp|http):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim
        replacedText = inputText.replace(
          replacePattern1,
          '<a href="$1" target="_blank">$1</a>'
        )

        //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
        replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim
        replacedText = replacedText.replace(
          replacePattern2,
          '$1<a href="http://$2" target="_blank">$2</a>'
        )

        //Change email addresses to mailto:: links.
        replacePattern3 =
          /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim
        replacedText = replacedText.replace(
          replacePattern3,
          '<a href="mailto:$1">$1</a>'
        )
      }

      return replacedText
    },
    // eslint-disable-next-line no-unused-vars
    hasDocumentExtension(text) {
      return true
      /*
      let extension = text.split(".");
      return extension.length > 2;*/
      // extension = extension[extension.length - 1].toLowerCase();
      // if (!extension) return false;
      // const invalidExtensions = ["com", "to", "co", "uy", "net", "edu"];
      // return !invalidExtensions.includes(extension)
    },
    isDocument(text) {
      if (text == undefined) return false
      try {
        const isValidURL = this.validURL(text)
        const isImage = this.checkImage(text)
        const hasDocExtension = this.hasDocumentExtension(text)
        return isValidURL && !isImage && hasDocExtension
      } catch (e) {
        console.error(e)
        return false
      }
    },
    isImageLink(text) {
      if (text == undefined) return false
      try {
        const isValidURL = this.validURL(text)
        const isImage = this.checkImage(text)
        return isValidURL && isImage
      } catch (e) {
        console.error(e)
        return false
      }
    },
    processText(text) {
      if (text) {
        const isValidURL = this.validURL(text)
        if (isValidURL) {
          return `<a href="${text}">${text}</a>`
        } else {
          const newText = this.addLinksText(text)

          return newText
        }
      }
    },
    showHSM(hsm) {
      this.hsm = hsm
      this.hsmPopup = true
    },
    hasHtmlTags(text) {
      return /<\s*\w+[^>]*>(.*?)<\s*\/\s*\w+>/.test(text)
    },
    autoScrollBottom() {
      setTimeout(() => {
        var el = document.getElementById('chatLog')
        if (el) {
          try {
            document.getElementById('chatLog').scrollTop =
              document.getElementById('chatLog').offsetHeight +
              document.getElementById('chatLog').scrollTop +
              document.getElementById('chatLog').scrollHeight
          } catch (e) {
            console.log(e)
          }
        }
      }, 10)
    },
    autoScrollBottomFake() {
      // console.log('autoScrollBottomFake')
    },
    autoScrollTop() {
      document.getElementById('chatLog').scrollTo(0, 0)
    },
    getMessagesLength(messages) {
      const aux = []
      messages.forEach(element => {
        if (aux.indexOf(element._id) === -1) {
          aux.push(element._id)
        }
      })
      return aux.length
    },
    avatarClass(msg) {
      return {
        // 'bg-warning-gradient text-white ml-5':
        //   (msg.isUser == 0 || msg.isUser == 2) &&
        //   this.activeChatUser.status == 1,
        // 'bg-success-gradient text-white ml-5':
        //   (msg.isUser == 0 || msg.isUser == 2) &&
        //   this.activeChatUser.status == 2,
        // 'bg-danger-gradient text-white ml-5':
        //   (msg.isUser == 0 || msg.isUser == 2) &&
        //   this.activeChatUser.status == 3,
        // 'border border-solid border-grey-light mr-5':
        //   msg.isUser == 1 || msg.isUser == 3,
        // 'bg-custom-gradient text-dark ml-5':
        //   this.activeChatUser.status == 0 &&
        //   (msg.isUser == 0 || msg.isUser == 2)
        'bg-warning-gradient text-white ml-5':
          (msg.isUser == 0 || msg.isUser == 2 || msg.isUser == 6) &&
          this.activeChatUser.status == 1,
        'bg-success-gradient text-white ml-5':
          (msg.isUser == 0 || msg.isUser == 2 || msg.isUser == 6) &&
          this.activeChatUser.status == 2,
        'bg-danger-gradient text-white ml-5':
          (msg.isUser == 0 || msg.isUser == 2 || msg.isUser == 6) &&
          this.activeChatUser.status == 3,
        'border border-solid border-grey-light mr-5':
          msg.isUser == 1 || msg.isUser == 3,
        // 'bg-custom-gradient text-white': this.activeChatUser.status == 0,
        'bg-custom-gradient text-white ml-5':
          msg.isUser == 0 || msg.isUser == 2 || msg.isUser == 6
      }
    },
    messageClass(msg) {
      return {
        'bg-warning-gradient text-white':
          (msg.isUser == 0 || msg.isUser == 2 || msg.isUser == 6) &&
          this.activeChatUser.status == 1,
        'bg-success-gradient text-white':
          (msg.isUser == 0 || msg.isUser == 2 || msg.isUser == 6) &&
          this.activeChatUser.status == 2,
        'bg-danger-gradient text-white':
          (msg.isUser == 0 || msg.isUser == 2 || msg.isUser == 6) &&
          this.activeChatUser.status == 3,
        'border border-solid border-grey-light bg-white':
          msg.isUser == 1 || msg.isUser == 3,
        // 'bg-custom-gradient text-white': this.activeChatUser.status == 0,
        'bg-custom-gradient text-white':
          msg.isUser == 0 || msg.isUser == 2 || msg.isUser == 6
      }
    },
    // isSameDay(time_to, time_from) {
    //   const date_time_to = new Date(Date.parse(time_to));
    //   const date_time_from = new Date(Date.parse(time_from));
    //   return (
    //     date_time_to.getFullYear() === date_time_from.getFullYear() &&
    //     date_time_to.getMonth() === date_time_from.getMonth() &&
    //     date_time_to.getDate() === date_time_from.getDate()
    //   );
    // },
    // toDate(time) {
    //   const locale = "en-us";
    //   const date_obj = new Date(Date.parse(time));
    //   const monthName = date_obj.toLocaleString(locale, {
    //     month: "short"
    //   });
    //   return date_obj.getDate() + " " + monthName;
    // },
    async getMessages(showMore, firstTime) {
      let limit, skip

      if (showMore) {
        limit = this.limit
        skip = this.skip
      } else {
        limit = this.shown
        skip = 0
      }

      this.skip = this.skip + this.defaultNumMessages
      this.shown = this.shown + this.defaultNumMessages

      this.$vs.loading({
        container: '#chat-app'
      })
      konecta
        .get(
          `/message?where[chat]=${this.activeChatUser._id}&sender=${this.activeChatUser.sender}&skip=${skip}&limit=${limit}`
        )
        .then(async response => {
          var responseMessages = response.data.result
          var messages = []

          let i
          for (i in responseMessages) {
            const message = responseMessages[i]
            if (message.type === 'event') {
              if (message.meta && message.meta.html) {
                message.specialEvent = 100
                const messageTime = this.$moment(message.ts)
                message.ts = messageTime
                message.event = {
                  type: 'html',
                  html: message.meta.html
                }
                messages.push(message)
              }
            } else {
              const r =
                message.isUser !== 6
                  ? await this.verifyMessage(message.text)
                  : null
              const messageTime = this.$moment(message.ts)
              message.ts = messageTime
              message.text = r
              if (
                (message.isUser === 1 ||
                  message.isUser === 3 ||
                  message.isUser === 6) &&
                message.output &&
                message.output.length > 0
              ) {
                let j
                const auxaux = []
                for (j in message.output) {
                  const element = message.output[j]
                  const aux = JSON.parse(JSON.stringify(message))
                  const response2 = await this.verifyMessage(
                    element.responseText
                  )
                  aux.text = response2

                  /** Is File Conversion */
                  if (element.responseFile && element.responseFile.url) {
                    aux.text.type = 'file'
                    const fileType = FILE_FORMATS_MAP[element.responseFile.type]
                    if (fileType == 'image') aux.text.type = 'image'
                    aux.text.name = element.responseText
                    aux.text.url = element.responseFile.url
                  }

                  aux.isUser = 2
                  aux.ts =
                    element.status == 1 || element.status === -1
                      ? undefined
                      : this.$moment(element._sentAt || message.ts)
                  // console.log('AUX0',JSON.stringify(aux, undefined, 2))
                  aux.status = element.status
                  aux.output = [element]
                  auxaux.push(aux)
                }
                const reverseAux = auxaux.reverse()
                messages = messages.concat(reverseAux)
              } else if (
                message.isUser === 6 &&
                message.meta &&
                message.meta.hsm
              ) {
                const aux = JSON.parse(JSON.stringify(message))
                aux.text = {
                  type: 'text',
                  text: JSON.stringify(message.meta.hsm.parameters)
                }
                aux.isUser = 2
                aux.ts = messageTime
                messages.push(aux)
              } else if (
                message.isUser === 6 &&
                message.meta &&
                message.meta.template
              ) {
                const aux = JSON.parse(JSON.stringify(message))
                aux.text = {
                  type: 'text',
                  text: JSON.stringify(message.meta.template.bodyParameters)
                }
                aux.isUser = 2
                aux.ts = messageTime
                // console.log('AUX0',JSON.stringify(aux, undefined, 2))
                messages.push(aux)
              } else if (message.isUser === 6) {
                const aux = JSON.parse(JSON.stringify(message))
                aux.text = {
                  type: 'text',
                  text: message.input[0]
                }
                aux.isUser = 2
                aux.ts = new Date().toISOString()
                // console.log('AUX0',JSON.stringify(aux, undefined, 2))
                messages.push(aux)
              }
              if (message.text) {
                messages.push(message)
              }
            }
          }

          setTimeout(() => {
            // this.$vs.loading.close()
            this.$vs.loading.close('#chat-app > .con-vs-loading')
            if (firstTime) {
              messages = messages.reverse()
            } else {
              const reverseMsg = messages.reverse()
              messages = reverseMsg.concat(this.activeChatMessages)
            }

            this.UPDATE_ACTIVE_MESSAGES(messages)
            this.messagesLength = this.getMessagesLength(messages)
            // console.log('!!!!', JSON.stringify(messages, undefined, 2))

            setTimeout(
              firstTime ? this.autoScrollBottom : this.autoScrollTop,
              200
            )
            // =========================================================================================
            //     CAMBIAR lenght POR length CUANDO CAMBIE EN EL BACKEND
            // ==========================================================================================
            this.length = response.data.length
          }, 200)


          this.getChatUserStatus()

        })
    },

    getPicture(isUser) {
      if (isUser == 1 || isUser == 3) return this.activeChatUser.picture
      if (isUser == 0) return null
      if (isUser == 2 || isUser == 6)
        return require('@/assets/images/portrait/chatbot-logo.png')
    },
    getMessagesSocket() {
      // Obtener mensajes socket
      //conectando por primera vez
      // console.log('acá!!!!')
      // if (
      //   oldVal &&
      //   oldVal.sender &&
      //   oldVal.service &&
      //   (oldVal.sender !== this.activeChatUser.sender ||
      //     oldVal.service !== this.activeChatUser.service)
      // ) {
      //   console.log('leave')
      //   this.$socket.client.emit('leave', {
      //     type: 'sender',
      //     service: oldVal.service,
      //     sender: oldVal.sender
      //   })
      // }
      // this.$socket.client.emit('join', {
      //   type: 'sender',
      //   service: this.activeChatUser.service,
      //   sender: this.activeChatUser.sender
      // })

      // eslint-disable-next-line no-unused-vars
      this.$socket.on('message', data => {})
    },
    ...mapMutations(['UPDATE_ACTIVE_MESSAGES', 'UPDATE_ATTACHMENT_FILE', 'UPDATE_CHAT_USER_STATUS']),
    ...mapActions(['verifyMessage']),
    // },
    // sockets: {
    async updateMessage(data) {
      /*
        Estrategia:
          Encontrar el intervalo de índices
          donde están los mensajes con el _id data.message._id.
          Luego sustituir cada uno de esos mensajes con el status
          actualizado.
      */
      if (['user', 'agent', 'callback'].indexOf(data.message.type) === -1) {
        return
      }
      const aux = JSON.parse(JSON.stringify(this.activeChatMessages))
      let firstIndex = null
      aux.some((element, i) => {
        if (element._id === data.message._id) {
          firstIndex = i
          return true
        }
      })
      if (firstIndex === null) {
        return
      }
      let lastIndex = null
      aux.some((element, i) => {
        if (element._id !== data.message._id && i > firstIndex) {
          lastIndex = i
          return true
        }
      })
      if (lastIndex !== null) {
        lastIndex -= 1 // porque en realidad es el anterior
      } else {
        lastIndex = aux.length - 1
      }
      const head = aux.slice(0, firstIndex)
      const tail = aux.slice(lastIndex + 1, aux.length)
      const _id = data.message._id
      const ts = new Date(data.message._createdAt)
      let messages = []
      let text = data.message.input[0]
      const response = await this.verifyMessage(text)
      // this.verifyMessage(text).then(async response => {
      if (response) {
        text = response
        messages.push({
          _id,
          isUser: data.message.type == 'user',
          text,
          ts
        })
      }
      if (
        (data.message.type == 'user' || data.message.type == 'callback') &&
        data.message.output &&
        data.message.output.length > 0
      ) {
        let j
        for (j in data.message.output) {
          const element = data.message.output[j]
          const aux = {}
          const response2 = await this.verifyMessage(element.responseText)
          aux._id = _id
          aux.text = response2
          aux.isUser = data.message.type == 'callback' ? 6 : 2
          aux.ts =
            element.status == 1 || element.status === -1
              ? undefined
              : this.$moment(element._sentAt || new Date())
          aux.status = element.status
          // console.log('AUX0',JSON.stringify(aux, undefined, 2))
          this.$log.info('CHAT LOG', aux)
          messages.push(aux)
        }
      }
      const result = head.concat(messages).concat(tail)
      this.UPDATE_ACTIVE_MESSAGES(result)
      this.messagesLength = this.getMessagesLength(result)
    },
    async message(data) {
      const ts = new Date().toISOString()
      const _id = data.message._id
      if (
        (this.activeChatUser.bot == data.bot,
        this.activeChatUser.sender == data.sender)
      ) {
        let messages = this.activeChatMessages
        if (data.message.type === 'event') {
          if (data.message.meta && data.message.meta.html) {
            data.message.specialEvent = 100
            const messageTime = this.$moment(data.message.ts)
            data.message.ts = messageTime
            data.message.event = {
              type: 'html',
              html: data.message.meta.html
            }
            messages.push(data.message)
          }
        } else {
          let text = data.message.input[0]

          const response =
            data.message.type !== 'callback'
              ? await this.verifyMessage(text)
              : null

          // this.verifyMessage(text).then(async response => {
          if (response) {
            text = response
            messages.push({
              _id,
              isUser: data.message.type == 'user',
              text,
              ts
            })
          }
          if (
            (data.message.type == 'user' || data.message.type == 'callback') &&
            data.message.output &&
            data.message.output.length > 0
          ) {
            let j
            for (j in data.message.output) {
              const element = data.message.output[j]
              const aux = {}
              const response2 = await this.verifyMessage(element.responseText)
              aux._id = _id
              aux.text = response2
              aux.isUser = data.message.type == 'callback' ? 6 : 2
              aux.ts =
                element.status == 1 || element.status === -1
                  ? undefined
                  : this.$moment(element._sentAt || new Date())
              aux.status = element.status
              // console.log('AUX0',JSON.stringify(aux, undefined, 2))
              this.$log.info('CHAT LOG', aux)
              messages.push(aux)
            }
          }
        }

        this.UPDATE_ACTIVE_MESSAGES(messages)
        this.messagesLength = this.getMessagesLength(messages)

        this.autoScrollBottom()
        // })
      }
    },
    getChatUserStatus(){
      if (this.activeChatUser.platform !== 'web') return
      if(!this.activeChatUser.sender && this.activeChatUser.senderId)
        this.activeChatUser.sender = this.activeChatUser.senderId
      if(!this.activeChatUser.bot || !this.activeChatUser.sender) return
      this.chatUserStatusRequests++
      if(this.chatUserStatusRequests >= 3) {
        if(this.$store.state.chatUserStatus !== CHAT_USER_STATUS.offline)
          this.UPDATE_CHAT_USER_STATUS(CHAT_USER_STATUS.offline)
        return
      }
      let data = {
        botId: this.activeChatUser.bot,
        senderId: this.activeChatUser.sender,
      }
      this.$socket.client.emit('getChatUserStatus', data)
    }
  },
  watch: {
    activeChatUser() {
      this.messages = []
      this.limit = this.defaultNumMessages
      this.shown = this.defaultNumMessages
      this.skip = 0
      this.chatUserStatusRequests = 0
      this.UPDATE_CHAT_USER_STATUS(CHAT_USER_STATUS.offline)
      this.getMessages(false, true)
      // this.getMessagesSocket(newVal, oldVal)
      this.UPDATE_ATTACHMENT_FILE('')
    },
    statusChat() {
      this.autoScrollBottom()
    }
  },
  mounted() {
    this.getMessages(false, true)
    this.UPDATE_CHAT_USER_STATUS(CHAT_USER_STATUS.offline)
    // this.getMessagesSocket()
    this.$notification
      .requestPermission() //solicitar permiso de notificación
      .then(console.log)

    this.$socket.client.on('updateMessage', this.updateMessage)
    this.$socket.client.on('message', this.message)
    this.getChatUserStatusInterval = setInterval(this.getChatUserStatus, this.timeMsToCheckChatUserStatus)
    this.$socket.client.on('chatUserStatus', data => {
      if (
        this.activeChatUser.bot.toString() === data.botId.toString() &&
        this.activeChatUser.sender.toString() === data.senderId.toString()
      ){
        this.UPDATE_CHAT_USER_STATUS(data.chatUserStatus)
        this.chatUserStatusRequests = 0
      }else{
        this.UPDATE_CHAT_USER_STATUS(CHAT_USER_STATUS.offline)
      }
    })
  },
  beforeDestroy() {
    this.$socket.client.off('updateMessage')
    this.$socket.client.off('message')
    this.$socket.client.off('chatUserStatus')
    clearInterval(this.getChatUserStatusInterval)
  }
}
</script>
<style scoped>
.bg-primary-gradient {
  opacity: 0.9 !important;
}

.img-message {
  max-width: 100%;
  border-radius: 7px;
}

.img-message:hover {
  cursor: pointer;
  opacity: 0.8;
}

.icon-file-white {
  background: rgba(255, 255, 255, 0.336) !important;
  border-radius: 7px;
  padding: 5px 10px;
  padding-top: 10px;
}

audio {
  background: #f1f3f4;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
  /* -moz-box-shadow: 2px 2px 4px 0px #006773;
  -webkit-box-shadow: 2px 2px 4px 0px #006773;
  box-shadow: 2px 2px 4px 0px #006773; */
  -moz-border-radius: 7px 7px 7px 7px;
  -webkit-border-radius: 7px 7px 7px 7px;
  border-radius: 7px 7px 7px 7px;
}

.chat-time {
  text-align: right;
  font-size: 0.7rem;
}
.flex.items-start .chat-time {
  text-align: left;
}
</style>

<style scoped>
.bg-custom-gradient {
  background: linear-gradient(
    118deg,
    rgb(231, 124, 92),
    rgba(163, 9, 9, 0.308)
  ) !important;
}
.bg-custom {
  background: linear-gradient(
    118deg,
    rgb(231, 124, 92),
    rgba(255, 255, 255, 0.507)
  ) !important;
}
</style>
